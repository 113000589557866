const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(8),
    // height: "100%",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.light,
    fontWeight: "bold",
    // display: "flex",
    // alignItems: "center",
    // marginLeft: theme.spacing(0),
    // fontWeight: "bold",
  },
});
export default styles;
